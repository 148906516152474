import * as Sentry from "@sentry/remix";
import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";

// window.ENV is set in app/root.tsx, ts definition for it:
declare global {
  interface Window {
    ENV?: { DEPLOY_ENV: string; SENTRY_RELEASE: string };
  }
}

polyfillCountryFlagEmojis();

Sentry.init({
  dsn: "https://0ea07fb9a34564c8f8328d0cf6e10b7b@o143345.ingest.us.sentry.io/4507374862794752",
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
  enabled: import.meta.env.PROD,
  environment: window.ENV?.DEPLOY_ENV || "unknown",
  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    Sentry.replayIntegration(),
  ],
});

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>
  );
});
